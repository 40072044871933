import Vue from "vue";
import App from "./App";
import router from "./router";
import "@babel/polyfill";

// 全局检测Bug
import Aegis from "aegis-web-sdk";
const whiteList = [
  "https://sport-cdn.qietongvip.com/AI-H5/model/movenet-model.json",
  "https://sport-cdn.qietongvip.com/AI-H5/model/group1-shard1of2.bin",
  "https://sport-cdn.qietongvip.com/AI-H5/model/group1-shard2of2.bin",
];
Vue.prototype.$aegis = new Aegis({
  id: "vqglEI47DE0JdkKq4m", // 项目key
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true,
  spa: true, // 页面切换的时候上报 pv
  api: {
    apiDetail: true,
    retCodeHandler(data, url) {
      // 注意这里拿到的data是string类型，如果需要对象需要手动parse下
      try {
        data = JSON.parse(data);
      } catch (e) {}
      console.log("retCodeHandler", url);
      if (whiteList.includes(url)) {
        return {
          isErr: false,
          code: 200,
        };
      } else {
        //因为我们的接口不存在retCode, 所以只判断code 不为200，此次请求就是错误的。此时只需进行以下配置
        return {
          // isErr 如果是 true 的话，会上报一条 retcode 异常的日志。
          isErr:
            data.code != 200 || data?.data?.code
              ? data?.data?.code != 200
              : false,
          code: data.code,
        };
      }
    },
  },
  websocketHack: true,
  ext1: (function () {
    if (process.env.NODE_ENV === "development") {
      return "AI打卡H5-开发环境";
    } else {
      if (process.env.VUE_APP_CURRENTMODE === "production") {
        return "AI打卡H5-生产环境";
      } else {
        return "AI打卡H5-开发环境";
      }
    }
  })(),
});

import * as tf from "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import "@tensorflow/tfjs-backend-cpu";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "@/styles/custom.less";
import rem from "@/utils/rem";
Vue.use(rem);

// import Vconsole from "vconsole";
// let vConsole = new Vconsole();
// Vue.use(vConsole);

// import api from "../common/api";
// Vue.prototype.$api = api
// console.log('api'+':>>>:', api)

//导入工具包
import utils from "./utils";
Vue.prototype.$utils = utils;
Vue.use(Antd);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  render: (h) => h(App),
});

app.$mount("#app");
